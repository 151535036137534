<template>
  <div id="app">
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no"
    />
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
html,
body {
  width: 100%;
  height: 100%;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
img {
  vertical-align: center;
  border: none;
}
#app {
  /* font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
  width: 100%;
  height: 100%;
}



input[type=date], input[type=datetime-local], input[type=email], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], textarea {
    /* border: none; */
    background-color: transparent;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    display: block;
    padding: 0;
    margin: 0;
   
    line-height: normal;
    color: #424242;
   
    font-family: inherit;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.van-picker__toolbar {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  height: 88px!important;
  line-height: 88px!important;
}
.van-picker__cancel,
.van-picker__confirm {
  padding: 0 32px!important;
  color: #1989fa;
  font-size: 28px!important;
  background-color: transparent;
  border: 0;
}
</style>
<style>
.el-input__inner{
  border: 1px solid #000;
}
.el-scrollbar__bar {
    opacity: 1 !important;
}

</style>