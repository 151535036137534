
import Vue from "vue"
import Router from 'vue-router'

Vue.use(Router)
export default new Router({
    routes: [
        {
            path: '/',
            name: 'index',
            meta: {
                title: '首页'
            },
            component: function(resolve) {
                require(["../view/index.vue"], resolve);
            }
        },
        {
            path: '/assessment',
            name: 'assessment',
            meta: {
                title: '考核完成'
            },
            component: function(resolve) {
                require(["../view/assessment.vue"], resolve);
            }
        },
        {
            path: '/taskInput',
            name: 'taskInput',
            meta: {
                title: '任务输入'
            },
            component: function(resolve) {
                require(["../view/taskInput.vue"], resolve);
            }
        },
        {
            path: '/ceshi',
            name: 'ceshi',
            meta: {
                title: '任务输入'
            },
            component: function(resolve) {
                require(["../view/ceshi.vue"], resolve);
            }
        },
    ]
})